import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import TextField from "@material-ui/core/TextField";
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'

import Container from "@material-ui/core/Container";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import * as REDUCER from "../reducers/shipmentReducer";

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export default ()=> {
 // The first commit of Material-UI
  const classes = useStyles();
  const proposalState = useSelector(state => state.proposalDetails);
  const dispatch = useDispatch();

  let timeFrom = parseISO(proposalState[0].pickupSchedule.pickup_date_from, "yyyy-MM-dd HH:mm", new Date());
  let timeTo = parseISO(proposalState[0].pickupSchedule.pickup_date_to, "yyyy-MM-dd HH:mm", new Date());

  useEffect(()=>{
    dispatch(REDUCER.setPickupDateTime(timeFrom, timeTo))}, []);


  if( timeFrom.getMinutes()> 30) timeFrom.setMinutes(30);
  if( timeFrom.getMinutes()< 30) timeFrom.setMinutes(0);
  if( timeTo.getMinutes()> 30) timeTo.setMinutes(30);
  if( timeTo.getMinutes()< 30) timeTo.setMinutes(0);

const handleDateChange = date => {
   dispatch(REDUCER.setPickupDate(date));
 };
const handleTimeFromChange = date => {
  dispatch(REDUCER.setPickupTimeFrom(date));
};
const handleTimeToChange = date => {
  dispatch(REDUCER.setPickupTimeTo(date));
};

return (
   <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Pickup goods on date"
          disablePast 
          format="MM/dd/yyyy"
          value={parseISO(proposalState[0].pickupSchedule.pickup_date_from, "yyyy-MM-dd HH:mm", new Date())}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker_to"
          label="From"
          ampm={proposalState[0].source_country.short_format === "US" || proposalState[0].source_country.short_format === "CA" ? true: false} 
          value={timeFrom}
          onChange={handleTimeFromChange}
          KeyboardButtonProps={{
            'aria-label': 'change time from',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker_from"
          label="Until "
          ampm={proposalState[0].source_country.short_format === "US" || proposalState[0].source_country.short_format === "CA" ? true: false} 
          value={timeTo}
          onChange={handleTimeToChange}
          KeyboardButtonProps={{
            'aria-label': 'change time until',
          }}
        />
        <TextField
          id="instructions"
          label="Pickup Instructions"
          className={classes.textField}
          value={proposalState[0].pickupSchedule.instructions}
          onChange={e => dispatch(REDUCER.setPickupInstructions(e.target.value))}
          helperText={`optional (${proposalState[0].pickupSchedule.instructions.length}/35)`}
          margin="normal"
          inputProps={{ maxLength: 35 }}
        />
      </MuiPickersUtilsProvider>
    </Container>
    )
}
