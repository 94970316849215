import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getShipments } from "../reducers/shipmentReducer";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
    marginTop: "20%",
    marginLeft: "50%"
  }
}));

export default () => {
  const proposalState = useSelector(state => state.proposalDetails);
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if( proposalState[0] == null)
    dispatch(
      getShipments(
        "",
        "",
        searchParams.get("guid") || (process.env !== "production" ? "28528ff0-9dfa-11ec-b824-0aa93117f346": "")
      )
    );
  }, []);

  const classes = useStyles();

  console.log(window.location.search);
  if (!proposalState[0] && proposalState.proposalErrors.length === 0)
    return (
      <Container>
        <Typography>
          Loading data. Please wait for the process to complete.
        </Typography>
        <CircularProgress className={classes.progress} />
      </Container>
    );
  if( proposalState.proposalErrors.length > 0)
      return (
        <Container>
        <Typography>
          {`Error: ${proposalState.proposalErrors}`}
        </Typography>
        <Typography>
          {`Please reload the page. If the problem persists, please contact PackJet at : support@packjet.co`}
        </Typography>
      </Container>
      )
  const proposal = proposalState[0];
  const numberOfPackages = proposal.packages.reduce((acc, pack) => {
    return pack.quantity + acc;
  }, 0);
  const totalWeight = proposal.packages.reduce((acc, pack) => {
    return acc + (pack.quantity * pack.net_weight);
  }, 0);
  return (
    <Container>
      <Box variant="subtitle1">
        {`You are about to fulfill the request from`}
        <Box
          fontWeight={700}
        >{`${proposal.target_address.address.name}, destination ${proposal.target_address.address.address2}, ${proposal.target_address.address.city2}, ${proposal.target_address.address.country_name}.`}</Box>
      </Box>
      <Typography variant="subtitle1">{`The PackJet shipment number is:`}</Typography>
      <Box fontWeight={700}>{`${proposal.shipment.number}.`}</Box>
      <Typography variant="subtitle1">
        {`The number of packages that has been entered is: ${numberOfPackages.toFixed(
          0
        )}.`}
      </Typography>
      <Typography variant="subtitle1">
        {`The total physical weight that has been entered is: ${totalWeight.toFixed(
          2
        )} kg.`}
      </Typography>
      <Typography variant="subtitle1">
        The selected carrier to fulfill the shipment is:
        <img
          style={{ height: "20px" }}
          src={`${proposal.shipment.carrier_image}`}
          alt={`${proposal.shipment.carrier_name}`}
          height="30"
          width="60"
        />
      </Typography>
      <Typography variant="subtitle1">
        In the next screen you will be asked to enter the address you are
        shipping from, the shipment content description and its value.
      </Typography>
      <Typography variant="subtitle1">
        After filling these details, Packjet will prepare the shipment labels
        and send them to your email address.
      </Typography>
      <Typography variant="subtitle1">
        You will then just need to schedule a suitable pickup time with:
        <img
          style={{ height: "20px" }}
          src={`${proposal.shipment.carrier_image}`}
          alt={`${proposal.shipment.carrier_name}`}
          height="30"
          width="60"
        />
      </Typography>
    </Container>
  );
};
